.Clocks {
  height: 100vh;
  position: relative;
  box-sizing: border-box;
}

body::-webkit-scrollbar {
  display: none;
}

html {
  scrollbar-width: none;
}

section {
  height: calc(100% / 3);
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom: 2px solid black;
}

div > p {
  width: 100%;
}

section p {
  margin: 0;
}

p.description {
  font-size: 3rem;
  font-weight: bold;
  height: 25%;
}

p.timer {
  font-size: 9rem;
  font-weight: 400;
  height: 75%;
}

p.hint {
  font-size: 6rem;
  font-weight: 400;
}

.main-clock {
  width: 50%;
  height: 100%;
  border-right: 2px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.clock-only {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.clock-only > p.timer {
  font-size: 18rem;
  font-weight: 400;
  height: 50%;
}

.mixer-clock {
  position: relative;
}

.block-container {
  width: 50%;
  height: 100%;
  border-right: 2px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.streaming-container {
  width: 50%;
  height: 100%;
  border-right: 2px solid #000;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
}

p.playing-stream {
  color: red;
}

.mixer-clock-clock {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: calc(100% - 80px);
}

.mixer-clock-clock > p.timer {
  height: 75%;
}

.mixer-clock-icons {
  display: flex;
  flex-direction: column;
  width: 80px;
}

.mixer-icon {
  cursor: pointer;
  width: 80px;
  height: auto;
}

img.priority {
  border: 2px #000 solid;
}

.block-buttons {
  width: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 0.5%;
}

.block-buttons button {
  margin-top: 0.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0;
  height: 3rem;
}

.block-buttons button img {
  width: 2rem;
}

.block-info {
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.block-clock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.settings {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.settings-mixer {
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}

.media-clock {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media-clock > p.timer {
  height: 68%;
}

.settings-mixer .settings-block {
  display: flex;
  flex-direction: column;
}

.settings-mixer .settings-block .form-label {
  font-size: 1.2rem;
}

.settings-mixer .settings-block .form-label span {
  color: red;
  font-weight: bold;
}

.settings-mixer .settings-block.all-invalid-true {
  margin-bottom: 1.3rem;
}

.settings-mixer .settings-block .all-invalid-true {
  margin-bottom: 1.3rem;
}

.api-btn {
  width: 20%;
}

.loader.large {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader.large img {
  width: 6rem;
}

.error-block {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-block img {
  width: 7rem;
}

.error-block .error-title {
  font-size: 2.5rem;
  margin: 2rem 0;
  color: red;
}

.error-block .error-description {
  font-size: 1.5rem;
  text-align: center;
}

.progress-bar-container {
  height: 7%;
  width: 100%;
  background: red;
}

.progress-bar-filler {
  height: 100%;
  background-color: #fff;
}

section.director-hints {
  align-items: start;
}

.block-director-hint {
  width: calc(100% - 150px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 2px solid black;
}

.hint-btn {
  margin-left: 0.3%;
}

.hint-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  background: rgba(0, 0, 0, 0.5);
}

.hint-modal-content {
  padding: 25px;
  background: white;
  border-radius: 16px;
  min-width: 900px;
  min-height: 200px;
}

.hint-modal.active {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hint-input {
  width: 100%;
  padding: 5px 15px;
  margin: 5px 0;
  border: 1px solid black;
  font-size: 3rem;
  text-align: center;
}

.hint-send-btn { 
  width: 50%;
  font-size: 1rem;
}

.block-audio-state {
  margin-right: 0.3%;
  width: 150px;
}

.block-audio-state > select {
  width: 150px;
}

.block-audio-state > img {
  width: 150px;
  height: 150px;
}

@media screen and (not ((not (max-width: 1208px)) and (not (max-height: 800px)))) {
  p.description {
    font-size: 2rem;
    font-weight: bold;
  }
  
  p.timer {
    font-size: 8rem;
    font-weight: 400;
  }

  .progress-bar-container {
    width: 100%;
    background: red;
  }
}

@media screen and (not ((not (max-width: 1108px)) and (not (max-height: 680px)))) {
  p.description {
    font-size: 2rem;
    font-weight: bold;
  }
  
  p.timer {
    font-size: 7rem;
    font-weight: 400;
  }

  .progress-bar-container {
    width: 100%;
    background: red;
  }
}

@media screen and (not ((not (max-width: 980px)) and (not (max-height: 590px)))) {
  p.description {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  p.timer {
    font-size: 6rem;
    font-weight: 400;
  }

  .progress-bar-container {
    width: 100%;
    background: red;
  }
}

@media screen and (max-height: 580px) {
  p.description {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  p.timer {
    font-size: 4rem;
    font-weight: 400;
  }

  .progress-bar-container {
    width: 100%;
    background: red;
  }

  .block-buttons button {
    margin-top: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.1rem 0;
    height: 2rem;
  }
  
  .block-buttons button img {
    width: 2rem;
  }

  .mixer-icon {
    cursor: pointer;
    width: 60px;
    height: auto;
  }

  .block-audio-state > img {
    width: 90px;
    height: 90px;
  }

  .hint-btn {
    margin-left: 0.3%;
    max-width: 90px;
  }
}
